import { useTranslation, Trans } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {postConfirmEmail} from "../../services/httpservice"
import { Button, Alert, TextField, Box, Stack, Typography } from "@mui/material";
import { IError } from "../../types/IError";
import { IAlertMessage } from "../../types/IAlertMessage";
import * as Sentry from "@sentry/react";
import PageWrapper from "../../components/PageWrapper";




const ConfirmEmail = () => {
  const [error, setError] = useState<IError>({hasError:false, errorText:""});
  const [alertMessage, setAlertMessage] = useState<IAlertMessage>({show:false, text:""});
  const { t } = useTranslation();

  const setSuccessAlert = (message:string) =>{
    setAlertMessage({show:true, text:message});
    setError({hasError:false, errorText:""});
  }
  const setErrorAlert = (message:string) =>{
    setAlertMessage({show:false, text:""});
    setError({hasError:true, errorText:message});
  }

  const handleConfirmClaim = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    await postConfirmEmail({
      email:urlParams.get("email") || "",
      confirmEmailToken:urlParams.get("token") || ""
    })
    .then(()=>{
      setSuccessAlert(t("confirmEmail.success_message"));
    })
    .catch((e)=>{
      Sentry.captureException(e);
      if(e?.response?.status == 406){
        setErrorAlert(e.response.data.message);
      }
      else{
        setErrorAlert(t("common.unknown_error"));
      }
    });
  };

  return (
    <PageWrapper>
      <Stack justifyContent="space-between" gap={30}>
        <Stack gap={4}>
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            <Trans i18nKey="confirmEmail.title">{t("title")}</Trans>
          </Typography>
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            <Trans i18nKey="confirmEmail.description">{t("description")}</Trans>
          </Typography>
          <Button variant="contained" onClick={handleConfirmClaim}>
                <Trans i18nKey="common.btnConfirm">
                  {t("btnConfirm")}
                </Trans>
              </Button>
        </Stack>
        {error.hasError ? 
            <Alert severity="error">{error.errorText}</Alert> : <></>}
        {alertMessage.show ? 
            <Alert severity="success">{alertMessage.text}</Alert> : <></>}
      </Stack>
    </PageWrapper>
  );
};

export default ConfirmEmail;
