import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Stack, Typography, AppBar, IconButton } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import { ImageLogo } from "../../assets";

import { routes } from "../../router";

interface IProps {
  title?: string;
}

const Header = ({ title }: IProps) => {
  const [user, setUser] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const email = window.localStorage.getItem("email");
    if (email) setUser(email);
  }, []);

  const handleLanding = () => navigate(routes.landing);

  const handleLogout = () => {
    window.localStorage.removeItem("email");
    navigate(routes.landing);
  };

  return (
    <AppBar
      color="inherit"
      sx={{
        pb: 0,
        boxShadow: "none",
      }}
    >
      <Box />
      <Stack>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {title ? (
            <Typography
              sx={{
                textAlign: "center",
              }}
            >
              {title}
            </Typography>
          ) : (
            <IconButton
              sx={{ width: "150px" }}
              disableFocusRipple={true}
              disableRipple={true}
              onClick={handleLanding}
            >
              <img
                src={ImageLogo}
                alt="New Loop"
                style={{ width: "inherit" }}
              />
            </IconButton>
          )}
          {user && (
            <IconButton
              sx={{
                position: "absolute",
                top: "50%",
                right: 0,
                transform: "translateY(-50%)",
              }}
              onClick={handleLogout}
            >
              <ExitToAppIcon sx={{ fontSize: 20, opacity: 0.5 }} />
            </IconButton>
          )}
        </Box>
        {user && (
          <Typography
            variant="body2"
            sx={{
              textAlign: "center",
              fontSize: 14,
              lineHeight: 0.1,
            }}
          >
            {user}
          </Typography>
        )}
      </Stack>
    </AppBar>
  );
};

export default Header;
