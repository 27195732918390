import { Box } from "@mui/material";
import Header from "../Header";

interface IProps {
  children: React.ReactNode;
}

const hasEmail = () => window.localStorage.getItem("email") != null;

const PageWrapper = ({ children }: IProps) => {
  return (
    <>
      <Header />
      <Box
        sx={{
          p: 2,
          pt: hasEmail() ? 18 : 12,
          pb: 8,
          m: "0 auto",
          maxWidth: "500px",
          height: "100vh",
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default PageWrapper;
