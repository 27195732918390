import axios from "axios";
import getLanguage from '../helper/LanguageHelper';
import {API_BASE_URL} from '../constants'
const headers = {
    method: "POST",
    accept: "text/plain",
    "content-type": "application/json",
    "X-Preferred-Language":getLanguage()
  };
  
export const postResetPassword = (requestBody: {
    email: string;
    token: string;
    password:string;
  }) => {
    if(!API_BASE_URL){
      throw "Invalid url";
    }
    return axios.post(
      `${API_BASE_URL}/Api/Auth/ResetPassword`,
      JSON.stringify(requestBody),
      { headers }
    );
  };


  export const postConfirmEmail = (requestBody: {
    email: string;
    confirmEmailToken: string;
  }) => {
    if(!API_BASE_URL){
      throw "Invalid url";
    }
    return axios.post(
      `${API_BASE_URL}/Api/Auth/ConfirmEmail`,
      JSON.stringify(requestBody),
      { headers }
    );
  };