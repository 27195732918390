import { useTranslation, Trans } from "react-i18next";
import { useState, useEffect } from "react";

import {postResetPassword} from "../../services/httpservice"
import { Button, Alert, TextField, Box, Stack, Typography } from "@mui/material";
import { IError } from "../../types/IError";
import { IAlertMessage } from "../../types/IAlertMessage";
import PageWrapper from "../../components/PageWrapper";
import * as Sentry from "@sentry/react";


const ResetPassword = () => {
    
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [inputError, setInputError] = useState<IError>({hasError:false, errorText:""});
  const [alertError, setAlertError] = useState<IError>({hasError:false, errorText:""});
  const [alertMessage, setAlertMessage] = useState<IAlertMessage>({show:false, text:""});

  useEffect(() => {validate();}, [password1, password2]);

  const { t } = useTranslation();



  const validate = () => {
    
    if(password1 != password2){
      setInputError({hasError:true, errorText:"Passwords are not equal"} );
    }
    else{
      setInputError({hasError:false, errorText:""});
    }
  };


  const setSuccessAlert = (message:string) =>{
    setAlertMessage({show:true, text:message});
    setAlertError({hasError:false, errorText:""});
  }

  const setErrorAlert = (message:string) =>{
    setAlertMessage({show:false, text:""});
    setAlertError({hasError:true, errorText:message});
  }


  const submit = async () => {
    
    if(inputError.hasError){
      return;
    }
    const urlParams = new URLSearchParams(window.location.search);
    
    try{
      await postResetPassword({
        email:urlParams.get("email") || "",
        token:urlParams.get("token") || "",
        password:password1
      });
      setSuccessAlert(t("resetPassword.success_message"));
    }
    catch(e:any){
      Sentry.captureException(e);
      if(e?.response?.status == 406){
        setErrorAlert(e.response.data.message);
      }
      else{
        setErrorAlert(t("common.unknown_error"));
      }
    }
  };

  return (
    <PageWrapper>
      <Stack justifyContent="space-between" gap={30}>
        <Stack gap={4}>
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            <Trans i18nKey="resetPassword.title">{t("title")}</Trans>
          </Typography>
          <Typography variant="body2">
            <Trans i18nKey="resetPassword.description">{t("description")}</Trans>
          </Typography>
          <Box>
            <TextField
              sx={{ width: "100%", mb: 2 }}
              label={
                <Trans i18nKey="resetPassword.newPassword.placeholder">
                  {t("placeholder")}
                </Trans>
              }
              type="password"
              variant="outlined"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
            />

            <TextField
              sx={{ width: "100%", mb: 2 }}
              label={
                <Trans i18nKey="resetPassword.repeatPassword.placeholder">
                  {t("placeholder")}
                </Trans>
              }
              type="password"
              variant="outlined"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              error={inputError.hasError}
              helperText={inputError.errorText}
            />


            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button variant="contained" onClick={submit} disabled={inputError.hasError}>
                <Trans i18nKey="common.btnContinue">
                  {t("btnContinue")}
                </Trans>
              </Button>
            </Box>
            
          </Box>
          {alertError.hasError ? 
            <Alert severity="error">{alertError.errorText}</Alert> : <></>}
        {alertMessage.show ? 
            <Alert severity="success">{alertMessage.text}</Alert> : <></>}
        </Stack>
        
      </Stack>
    </PageWrapper>
  );
};

export default ResetPassword;
