import { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { ThemeDark, ThemeLight } from "./themes";

const CustomThemeProvider = ({ children }: any) => {
  const [themeChoice, setThemeChoice] = useState<object>(ThemeLight);

  useEffect(() => {
    setThemeChoice(false ? ThemeDark : ThemeLight);
    // eslint-disable-next-line
  }, []);

  return (
    <ThemeProvider theme={responsiveFontSizes(createTheme(themeChoice))}>
      {children}
    </ThemeProvider>
  );
};

export default CustomThemeProvider;
