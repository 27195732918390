import { createBrowserRouter, redirect } from "react-router-dom";

import Root from "../pages/Root";
import Landing from "../pages/Landing";
import ResetPassword from "../pages/ResetPassword";
import ConfirmEmail from "../pages/ConfirmEmail/ConfirmEmail";

export enum routes {
  landing = "/",
  resetpassword = "/resetpassword",
  confirmEmail = "/confirmEmail",
}

const routesArray = [
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: routes.landing,
        element: <Landing />,
      },
    ],
  },
  {
    path: routes.resetpassword,
    element: <ResetPassword />,
  },
  {
    path: routes.confirmEmail,
    element: <ConfirmEmail />,
  }
];

export const router = createBrowserRouter(routesArray);
