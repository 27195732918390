import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const Root = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
      }}
    >
      <Outlet />
    </Box>
  );
};

export default Root;
