import { theme } from "./base";
import { PaletteMode } from "@mui/material";

export const ThemeLight = {
  ...theme,
  typography: {
    ...theme.typography,
  },
  components: {
    ...theme.components,
  },
  palette: {
    ...theme.palette,
    mode: "light" as PaletteMode,
    primary: {
      main: "#CCE7DA",
    },
    secondary: {
      main: "#FF00FF",
    },
  },
};

export default ThemeLight;
