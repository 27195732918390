import { red } from "@mui/material/colors";

// A custom theme for this app
export const theme = {
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontSize: 20,
    allVariants: {
      lineHeight: 1.2,
    },
    h3: {
      fontWeight: 700,
      fontSize: 32,
    },
    h4: {
      fontWeight: 500,
      fontSize: 24,
    },
    body1: {
      fontSize: 20,
      fontWeight: 500,
    },
    body2: {
      fontSize: 16,
    },
    caption: {
      fontSize: 45,
      fontWeight: 500,
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        a: {
          textDecoration: "none",
          cursor: "pointer",
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          transform: "inherit",
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },

    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: "large",
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "1rem",
          lineHeight: 1.75,
          bgcolor: "#cccccc",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "1rem !important",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#1F4345",
      light: "#F3FAFA",
    },
    secondary: {
      main: "#F2994A",
    },
    error: {
      main: red.A400,
    },
    success: {
      main: "#60b258",
    },
  },
};
