import React from "react";
import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { RouterProvider } from "react-router-dom";
import "./i18n";

import { router } from "./router";
import CustomThemeProvider from "./CustomThemeProvider";
import * as Sentry from "@sentry/react";
import "./index.css";

Sentry.init({
  dsn: "https://2781b0e438d646499a392171ded91c8d@o4505169907744768.ingest.sentry.io/4505169909514240",
  // integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 0,
  // Session Replay
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
  enabled: window.location.href.indexOf('localhost') == -1
});


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <CustomThemeProvider>
    <CssBaseline />
    <RouterProvider router={router} />
  </CustomThemeProvider>
);
