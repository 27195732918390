import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

import { Button, Stack, Typography, Link as MUILink } from "@mui/material";

import { routes } from "../../router";

import PageWrapper from "../../components/PageWrapper";

const Landing = () => {
  const [user, setUser] = useState("");
  const { t } = useTranslation();
  
  useEffect(() => {
    const email = window.localStorage.getItem("email");
    if (email) setUser(email);
  }, []);

  return (
    <PageWrapper>
      <Stack justifyContent="space-between" gap={30}>
        <Stack gap={4} justifyContent={"center"}>
          <Typography variant="h4" sx={{ textAlign: "center", mb: "20px" }}>
            <Trans i18nKey="landing.title">{t("title")}</Trans>
          </Typography>
          <Stack gap={1}>
            <Typography
              variant="body2"
              sx={{ textAlign: "center", fontStyle: "italic" }}
            >
              "<Trans i18nKey="landing.description">{t("description")}</Trans>
            </Typography>
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              <strong>130.000.000</strong>
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: "center", fontStyle: "italic" }}
            >
              <Trans i18nKey="landing.quantity">{t("quantity")}</Trans>"
            </Typography>
          </Stack>
          <Stack
            flexDirection="row"
            justifyContent="center"
            gap={2}
            sx={{ mt: "50px" }}
          >
            <Button variant="contained">
              <MUILink
                href="https://thenewloop.dk/"
                underline="none"
                sx={{ color: "inherit" }}
              >
                <Trans i18nKey="landing.btnMore">{t("btnMore")}</Trans>
              </MUILink>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </PageWrapper>
  );
};

export default Landing;
